<template>
  <div>
    <!-- แถบแรก -->
    <b-row>
      <b-col cols="md-12 col-lg-6">
        <b-card no-body>
          <b-card-header class="d-flex justify-content-between align-items-end">
            <h4 class="mb-0">
              ผลประกอบการวันนี้
            </h4>
          </b-card-header>
          <b-card-body class="px-0 pb-0">
            <b-row class="text-center mx-0">
              <b-col
                cols="4"
                class="border-top border-right d-flex align-items-between flex-column py-1"
              >
                <p class="mb-50">
                  ยอดฝากวันนี้
                </p>
                <p class="text-bold-700 mb-50">
                  {{ toMoney(result.txt_report_all_day_deposit) }} ฿
                </p>
              </b-col>
              <b-col
                cols="4"
                class="border-top border-right d-flex align-items-between flex-column py-1"
              >
                <p class="mb-50">
                  ยอดถอนวันนี้
                </p>
                <p class="text-bold-700 mb-50">
                  {{ toMoney(result.txt_report_all_day_withdraw) }} ฿
                </p>
              </b-col>
              <b-col
                cols="4"
                class="border-top d-flex align-items-between flex-column py-1"
              >
                <p class="mb-50">
                  กำไรสุทธิ์วันนี้
                </p>
                <p class="text-bold-700 mb-50">
                  {{ toMoney(result.txt_report_all_day_total) }} ฿
                </p>
              </b-col>
              <b-col
                cols="4"
                class="border-top d-flex align-items-between flex-column py-1"
              >
                <p class="mb-50">
                  สมาชิกวันนี้
                </p>
                <p class="text-bold-700 mb-50">
                  {{ result.txt_report_all_day_member }}
                </p>
              </b-col>
              <b-col
                cols="4"
                class="border-top d-flex align-items-between flex-column py-1"
              >
                <p class="mb-50">
                  รายการฝากวันนี้
                </p>
                <p class="text-bold-700 mb-50">
                  {{ result.txt_report_all_day_deposit_count }}
                </p>
              </b-col>
              <b-col
                cols="4"
                class="border-top d-flex align-items-between flex-column py-1"
              >
                <p class="mb-50">
                  รายการถอนวันนี
                </p>
                <p class="text-bold-700 mb-50">
                  {{ result.txt_report_all_day_withdraw_count }}
                </p>
              </b-col>
            </b-row>
          </b-card-body>
        </b-card>
      </b-col>
      <b-col cols="12 col-sm-6 col-lg-3" class="match-height">
        <b-card no-body>
          <b-card-body class="pb-0">
            <b-avatar
              class="mb-1"
              variant="light-primary"
              size="45"
            >
              <feather-icon
                icon="UsersIcon"
                size="21"
                class="font-medium-5"
              />
            </b-avatar>
            <div class="truncate">
              <h2 class="mb-25 font-weight-bolder"> {{ result.txt_report_all_day_member_total }} </h2>
              <span>สมาชิกทั้งหมด</span>
            </div>
          </b-card-body>
          <div style="min-height: 91px;">
            <vue-apex-charts
              type="area"
              height="100"
              width="100%"
              :options="chartOptionsComputed"
              :series="chartSeries"
            />
          </div>
        </b-card>
      </b-col>
      <b-col cols="12 col-sm-6 col-lg-3" class="match-height">
        <b-card no-body>
          <b-card-body class="pb-0">
            <b-avatar
              class="mb-1"
              variant="light-warning"
              size="45"
            >
              <feather-icon
                icon="UserPlusIcon"
                size="21"
                class="font-medium-5"
              />
            </b-avatar>
            <div class="truncate">
              <h2 class="mb-25 font-weight-bolder"> {{ result.txt_report_all_day_member_total_today }} </h2>
              <span>สมาชิกฝากวันนี้</span>
            </div>
          </b-card-body>
          <div style="min-height: 91px;">
            <vue-apex-charts
              type="area"
              height="100"
              width="100%"
              :options="chartOptionsComputed2"
              :series="chartSeries2"
            />
          </div>
        </b-card>
      </b-col>
    </b-row>
    <!-- สิ้นสุดแถบแรก -->
    <b-row>
      <b-col cols="12 col-sm-12">
        <b-card no-body>
          <div class="card-content">
            <b-table
              :fields="fieldBank"
              :items="tableBank"
              responsive
              table-class="table-hover-animation" show-empty>
              <template #empty="">
                <div class="align-items-center text-center">ไม่พบข้อมูล</div>
              </template>
              <template #emptyfiltered="">
                <div class="align-items-center text-center">ไม่พบข้อมูล</div>
              </template>
              <template #cell(bank)="data">
                <ul class="list-unstyled users-list m-0  d-flex align-items-center">
                  <li
                    data-toggle="tooltip"
                    data-popup="tooltip-custom"
                    data-placement="bottom"
                    :data-original-title="(typeof(bank_list[data.item.bank_code]) != undefined ? bank_list[data.item.bank_code] : '-')"
                    class="avatar pull-up m-0"
                  >
                    <img
                      class="media-object rounded"
                      :src="BASE_URL + 'assets/app-assets/images/bank/' + bank_admin[data.item.bank_code] + '.png'"
                      alt="Bank"
                      height="40"
                      width="40"
                    >
                  </li>
                </ul>
              </template>
              <template #cell(status_withdraw)="data">
                <b-badge :variant="statusBank[2][data.value]">
                  {{ statusBank[0][data.value] }}
                </b-badge>
              </template>
              <template #cell(status)="data">
                <b-badge :variant="statusBank[2][data.value]">
                  {{ statusBank[1][data.value] }}
                </b-badge>
              </template>
              <template #cell(balance)="data">
                <span class="text-primary" >{{ toMoney(data.value) }}</span>
              </template>
              <template #cell(fund)="data">
                <span class="text-primary" @click="setFund(data.item.id, data.value || 0)">{{ toMoney(data.value || 0) }}</span>
              </template>
              <template #cell(profit)="data">
                <span class="text-primary" >{{ toMoney(data.item.balance - (data.item.fund || 0)) }}</span>
              </template>
            </b-table>
          </div>
        </b-card>
      </b-col>
    </b-row>
    <!-- End Bank -->
    <b-row>
      <!-- รายการถอน -->
      <b-col cols="12 col-md-6">
        <b-card no-body>
          <b-card-header>
            <h4 class="mb-0 danger">
              รายการถอน
            </h4>
          </b-card-header>
          <div class="card-content">
            <b-table
              :fields="fieldWithdraw"
              :items="tableWithdraw"
              responsive
              table-class="table-hover-animation" show-empty>
              <template #empty="">
                <div class="align-items-center text-center">ไม่พบข้อมูล</div>
              </template>
              <template #emptyfiltered="">
                <div class="align-items-center text-center">ไม่พบข้อมูล</div>
              </template>
              <template #cell(status)="data">
                <div
                  role="status"
                  class="spinner-grow"
                  :class="{ 'text-warning': (data.value == 0 || data.value == 4), 'text-success': (data.value == 1 || data.value == 3), 'text-danger': data.value == 2 }"
                >
                  <span class="sr-only">Loading...</span>
                </div>
                {{ data.value == 0 ? 'รออนุมัติ' : data.value == 1 ? 'อนุมัติ (ถอนออโต้)' : data.value == 2 ? 'ไม่อนุมัติ' : data.value == 3 ? 'อนุมัติ (ถอนมือ)' : 'ดำเนินการถอนออโต้' }}
              </template>
              <template #cell(bank)="data">
                <ul class="list-unstyled users-list m-0  d-flex align-items-center">
                  <li
                    data-toggle="tooltip"
                    data-popup="tooltip-custom"
                    data-placement="bottom"
                    :data-original-title="(typeof(bank_list[Number(data.value)]) != undefined ? bank_list[Number(data.value)] : '-')"
                    class="avatar pull-up m-0"
                  >
                    <img
                      class="media-object rounded"
                      :src="BASE_URL + 'assets/app-assets/images/bank/' + bank_admin[data.value] + '.png'"
                      alt="Bank"
                      height="40"
                      width="40"
                    >
                  </li>
                </ul>
              </template>
              <template #cell(amount)="data">
                {{ toMoney(data.value) }}
              </template>
            </b-table>
          </div>
        </b-card>
      </b-col>
      <!-- รายการฝาก -->
      <b-col cols="12 col-md-6">
        <b-card no-body>
          <b-card-header>
            <h4 class="mb-0 danger">
              รายการฝาก
            </h4>
          </b-card-header>
          <div class="card-content">
            <b-table
              :fields="fieldDeposit"
              :items="tableDeposit"
              responsive
              table-class="table-hover-animation" show-empty>
              <template #empty="">
                <div class="align-items-center text-center">ไม่พบข้อมูล</div>
              </template>
              <template #emptyfiltered="">
                <div class="align-items-center text-center">ไม่พบข้อมูล</div>
              </template>
              <template #cell(status)="data">
                <div
                  role="status"
                  class="spinner-grow"
                  :class="{ 'text-warning': (data.value == 0 || data.value == 4), 'text-success': (data.value == 1 || data.value == 3), 'text-danger': data.value == 2 }"
                >
                  <span class="sr-only">Loading...</span>
                </div>
                {{ data.value == 0 ? 'รอดำเนินการ' : data.value == 1 ? 'เติมแล้ว (ฝากออโต้)' : data.value == 2 ? 'ไม่สำเร็จ' : data.value == 3 ? 'เติมแล้ว (ฝากมือ)' : 'ดำเนินการเติมเครดิต' }}
              </template>
              <template #cell(bank)="data">
                <ul class="list-unstyled users-list m-0  d-flex align-items-center">
                  <li
                    data-toggle="tooltip"
                    data-popup="tooltip-custom"
                    data-placement="bottom"
                    :data-original-title="(typeof(bank_list[Number(data.value)]) != undefined ? bank_list[Number(data.value)] : '-')"
                    class="avatar pull-up m-0"
                  >
                    <img
                      class="media-object rounded"
                      :src="BASE_URL + 'assets/app-assets/images/bank/' + bank_admin[data.value] + '.png'"
                      alt="Bank"
                      height="40"
                      width="40"
                    >
                  </li>
                </ul>
              </template>
              <template #cell(amount)="data">
                {{ toMoney(data.value) }}
              </template>
            </b-table>
          </div>
        </b-card>
      </b-col>
    </b-row>
    <!-- End Record -->
    
    <b-row>
      <!-- รายการถอน -->
      <b-col cols="lg-4">
        <b-card no-body>
          <b-card-header>
            <b-card-title class="ml-25">
              ทามไลน์การถอน
            </b-card-title>
          </b-card-header>
          <b-card-body>
            <app-timeline>
              <app-timeline-item 
                v-for="withd in timeline_withdraw" 
                :key="withd.subtitle" 
                variant="danger"
              >
                <div class="d-flex justify-content-between flex-sm-row flex-column mb-sm-0 mb-1">
                  <h6>{{ withd.title }}</h6>
                  <small class="timeline-item-time text-nowrap text-muted ml-1">{{ withd.duration }}</small>
                </div>
                <p>{{ withd.subtitle }}</p>
                <b-media no-body>
                  <b-media-aside class="mr-50">
                    <b-avatar
                      :src="withd.avatar"
                      size="38"
                    />
                  </b-media-aside>
                  <b-media-body class="my-auto">
                    <h6 class="mb-0">
                      {{ withd.avatarName }}
                    </h6>
                    <p class="mb-0 text-primary">
                      <router-link :to="'/withdraw/withdraw_form_detail/' + toID(withd.occupation)">{{ withd.occupation }}</router-link>
                    </p>
                  </b-media-body>
                </b-media>
              </app-timeline-item>
            </app-timeline>
            <!--/ timeline -->
          </b-card-body>
        </b-card>
      </b-col>
      <!-- รายการฝาก -->
      <b-col cols="lg-4">
        <b-card no-body>
          <b-card-header>
            <b-card-title class="ml-25">
              ทามไลน์การฝาก
            </b-card-title>
          </b-card-header>
          <b-card-body>
            <app-timeline>
              <app-timeline-item 
                v-for="depos in timeline_deposit" 
                :key="depos.subtitle" 
                variant="success"
              >
                <div class="d-flex justify-content-between flex-sm-row flex-column mb-sm-0 mb-1">
                  <h6>{{ depos.title }}</h6>
                  <small class="timeline-item-time text-nowrap text-muted ml-1">{{ depos.duration }}</small>
                </div>
                <p>{{ depos.subtitle }}</p>
                <b-media no-body>
                  <b-media-aside class="mr-50">
                    <b-avatar
                      :src="depos.avatar"
                      size="38"
                    />
                  </b-media-aside>
                  <b-media-body class="my-auto">
                    <h6 class="mb-0">
                      {{ depos.avatarName }}
                    </h6>
                    <p class="mb-0 text-primary">
                      <router-link :to="'/deposit/deposit_form_detail/' + toID(depos.occupation)">{{ depos.occupation }}</router-link>
                    </p>
                  </b-media-body>
                </b-media>
              </app-timeline-item>
            </app-timeline>
            <!--/ timeline -->
          </b-card-body>
        </b-card>
      </b-col>
      <!-- รายการเข้าใช้งาน -->
      <b-col cols="lg-4">
        <b-card
          class="card-app-design"
        >
          <b-badge variant="light-primary">
            {{ active.date }}
          </b-badge>
          <b-card-title class="mt-1 mb-75">
            {{ active.title }}
          </b-card-title>
          <b-card-text class="font-small-2 mb-2">
            {{ active.subtitle }}
          </b-card-text>

          <!-- design group -->
          <div class="design-group">
            <h6 class="section-label">
              Role
            </h6>
            <b-badge
              v-for="team in active.teams"
              :key="team.color"
              :variant="team.color"
              class="mr-1"
            >
              {{ team.name }}
            </b-badge>
          </div>
          <div class="design-group">
            <h6 class="section-label">
              Members
            </h6>
            <b-avatar
              v-for="(member,index) in active.members"
              :key="index"
              :variant="member.color"
              :text="member.text"
              :src="member.img"
              size="54"
              class="mr-1 mb-1"
              badge
              :badge-variant="member.online ? 'success':'secondary'"
            />
          </div>
          <!--/ design group -->

          <div class="design-planning-wrapper">
            <div
              v-for="plan in active.planing"
              :key="plan.title"
              class="design-planning"
            >
              <p class="card-text mb-25">
                {{ plan.title }}
              </p>
              <h6 class="mb-0">
                {{ plan.subtitle }}
              </h6>
            </div>
          </div>
        </b-card>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import {
  BCard, BCardHeader, BCardBody, BCardText, BCol, BRow, BTable, BBadge, BImg, VBTooltip,
  BMedia, BMediaBody, BMediaAside, BAvatar, BAvatarGroup, BCardTitle, 
} from 'bootstrap-vue'
import VueApexCharts from 'vue-apexcharts'
import { $themeColors } from '@themeConfig'
import { areaChartOptions } from '@core/components/statistics-cards/chartOptions'
import AppTimeline from '@core/components/app-timeline/AppTimeline.vue'
import AppTimelineItem from '@core/components/app-timeline/AppTimelineItem.vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  components: {
    VueApexCharts,
    BCard,
    BCardHeader,
    BCardBody,
    BCardText,
    BCol,
    BRow,
    BTable,
    BBadge,
    BAvatar,
    AppTimeline,
    AppTimelineItem,
    BCardTitle,
    BMedia,
    BMediaBody,
    BMediaAside,
    BAvatarGroup,
    BImg,
  },
  directives: {
    'b-tooltip': VBTooltip,
  },
  data() {
    return {
      BASE_URL: process.env.BASE_URL,
      timeline_withdraw: [],
      timeline_deposit: [],
      active: {
        date: '',
        title: 'เข้าสู่ระบบหลังบ้าน',
        subtitle: 'รายชื่อแอดมินที่เข้าใช้งานระบบหลังบ้าน',
        teams: [],
        members: [],
        planing: [],
      },
      result: {
        txt_report_all_day_deposit: 0,
        txt_report_all_day_withdraw: 0,
        txt_report_all_day_total: 0,
        txt_report_all_day_member: 0,
        txt_report_all_day_deposit_count: 0,
        txt_report_all_day_withdraw_count: 0,
        txt_report_all_day_member_total: 0,
        txt_report_all_day_member_total_today: 0,
      },
      chartOptions: null,
      chartSeries: [{name:'Members',data:[28,40,36,52,38,60,55]}],
      chartSeries2: [{name:'Members',data:[28,40,36,52,38,60,55]}],
      bank_admin: {'01': '1', '02': '2', '03': '3', '04': '5', '05': '6', '06': '4', '07': '7', '08': '9', '09': 'baac'},
      bank_list: JSON.parse('{"01":"\u0e01\u0e23\u0e38\u0e07\u0e40\u0e17\u0e1e","02":"\u0e01\u0e2a\u0e34\u0e01\u0e23\u0e44\u0e17\u0e22","03":"\u0e01\u0e23\u0e38\u0e07\u0e44\u0e17\u0e22","04":"\u0e17\u0e2b\u0e32\u0e23\u0e44\u0e17\u0e22","05":"\u0e44\u0e17\u0e22\u0e1e\u0e32\u0e13\u0e34\u0e0a\u0e22\u0e4c","06":"\u0e01\u0e23\u0e38\u0e07\u0e28\u0e23\u0e35\u0e2d\u0e22\u0e38\u0e18\u0e22\u0e32","07":"\u0e2d\u0e2d\u0e21\u0e2a\u0e34\u0e19","08":"\u0e18\u0e19\u0e0a\u0e32\u0e15\u0e34","09":"\u0e18.\u0e01.\u0e2a.","1":"\u0e01\u0e23\u0e38\u0e07\u0e40\u0e17\u0e1e","2":"\u0e01\u0e2a\u0e34\u0e01\u0e23\u0e44\u0e17\u0e22","3":"\u0e01\u0e23\u0e38\u0e07\u0e44\u0e17\u0e22","4":"\u0e17\u0e2b\u0e32\u0e23\u0e44\u0e17\u0e22","5":"\u0e44\u0e17\u0e22\u0e1e\u0e32\u0e13\u0e34\u0e0a\u0e22\u0e4c","6":"\u0e01\u0e23\u0e38\u0e07\u0e28\u0e23\u0e35\u0e2d\u0e22\u0e38\u0e18\u0e22\u0e32","7":"\u0e2d\u0e2d\u0e21\u0e2a\u0e34\u0e19","8":"\u0e18\u0e19\u0e0a\u0e32\u0e15\u0e34","9":"\u0e18.\u0e01.\u0e2a."}'),
      fieldBank: [
        { key: 'bank', label: 'ธนาคาร' },
        { key: 'bank_name', label: 'ชื่อธนาคาร', class: 'text-center text-nowrap' },
        { key: 'account_name', label: 'ชื่อบัญชี', class: 'text-center text-nowrap' },
        { key: 'bank_number', label: 'เลขบัญชี', class: 'text-center' },
        { key: 'balance', label: 'ยอดคงเหลือ', class: 'text-center' },
        { key: 'fund', label: 'ทุนสำรอง', class: 'text-center' },
        { key: 'profit', label: 'กำไรสุธิ', class: 'text-center' },
        { key: 'status_withdraw', label: 'ประเภท', class: 'text-center' },
        { key: 'status', label: 'สถานะ', class: 'text-center' },
        { key: 'created_at', label: 'เข้าสู่ระบบเมื่อ', class: 'text-center text-nowrap' },
      ],
      tableBank: [
      ],
      statusBank: [
        { 0: 'ธนาคารฝาก', 1: 'ธนาคารถอน'},
        { 0: 'ปิดใช้งาน', 1: 'เปิดใช้งาน'},
        { 0: 'danger', 1: 'success'},
      ],
      fieldWithdraw: [
        { key: 'full_name', label: 'ชื่อ', class: 'text-nowrap' },
        { key: 'bank', label: 'ธนาคาร', class: 'text-center' },
        { key: 'bank_number', label: 'เลขบัญชี', class: 'text-nowrap' },
        { key: 'amount', label: 'จำนวนเงิน', class: 'text-right text-nowrap' },
        { key: 'status', label: 'สถานะ', class: 'text-nowrap'  },
        { key: 'created_at', label: 'สั่งถอนเมื่อ', class: 'text-nowrap'  },
      ],
      tableWithdraw: [
      ],
      fieldDeposit: [
        { key: 'full_name', label: 'ชื่อ', class: 'text-nowrap'  },
        { key: 'bank', label: 'ธนาคาร', class: 'text-center' },
        { key: 'bank_number', label: 'เลขบัญชี', class: 'text-nowrap'  },
        { key: 'amount', label: 'จำนวนเงิน', class: 'text-right text-nowrap' },
        { key: 'status', label: 'สถานะ', class: 'text-nowrap'  },
        { key: 'created_at', label: 'ฝากเมื่อ', class: 'text-nowrap'  },
      ],
      tableDeposit: [
      ],
      t_fetch_bank: 0,
      t_fetch_report_all_day: 0,
      t_fetch_history_withdraw: 0,
      t_fetch_history_deposit: 0,
    }
  },
  computed: {
    chartOptionsComputed() {
      if (this.chartOptions === null) {
        const options = JSON.parse(JSON.stringify(areaChartOptions))
        options.theme.monochrome.color = $themeColors['primary']
        return options
      }
      return this.chartOptions
    },
    chartOptionsComputed2() {
      const options = JSON.parse(JSON.stringify(areaChartOptions))
      options.theme.monochrome.color = $themeColors['warning']
      return options
    },
  },
  mounted() {
    this.fetch_bank()
    this.fetch_report_all_day()
    this.fetch_history_withdraw()
    this.fetch_history_deposit()
    this.t_fetch_bank = setInterval(() => {
      this.fetch_bank()
    }, 5000)
    this.t_fetch_report_all_day = setInterval(() => {
      this.fetch_report_all_day()
    }, 15000)
    this.t_fetch_history_withdraw = setInterval(() => {
      this.fetch_history_withdraw()
    }, 15000)
    this.t_fetch_history_deposit = setInterval(() => {
      this.fetch_history_deposit()
    }, 15000)
  },
  methods: {
    toID(text) {
      let res = text.match(/([0-9])\w+/)
      return res ? res[0] : 0
    },
    toMoney(value) {
      const parts = parseFloat(value).toFixed(2).split('.')
      return parts[0].replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,') + (parts[1] ? `.${parts[1]}` : '')
    },
    load(data) {
      this.result.txt_report_all_day_deposit = Number(data.deposit)
      this.result.txt_report_all_day_deposit_count = Number(data.deposit_count)
      this.result.txt_report_all_day_withdraw = Number(data.withdraw)
      this.result.txt_report_all_day_withdraw_count = Number(data.withdraw_count)
      this.result.txt_report_all_day_total = Number(data.total)
      this.result.txt_report_all_day_member = Number(data.member)
      this.result.txt_report_all_day_member_total = Number(data.member_total)
      this.result.txt_report_all_day_member_total_today = Number(data.member_total_today)
      if (data.timeline) {
        if (data.timeline.deposit) this.timeline_deposit = data.timeline.deposit;
        if (data.timeline.withdraw) this.timeline_withdraw = data.timeline.withdraw;
      }
      if (data.system)
        this.active = data.system;
    },
    fetch_bank() {
      let url = 'bank/bank_list?status=1&security=1&group_by=bank_number'
      this.$http.get(url).then(res => { this.tableBank = res.data.result }).catch(()=>{})
    },
    fetch_report_all_day() {
      let url = 'home/report_summary_all_day'
      this.$http.get(url).then(res => { this.load(res.data.result) }).catch(()=>{})
    },
    fetch_history_withdraw() {
      let url = 'home/report_history_withdraw?start=0&length=5'
      this.$http.get(url).then(res => { this.tableWithdraw = res.data.result }).catch(()=>{})
    },
    fetch_history_deposit() {
      let url = 'home/report_history_deposit?start=0&length=5'
      this.$http.get(url).then(res => { this.tableDeposit = res.data.result }).catch(()=>{})
    },
    setFund(id, fund) {
      let url = 'bank/bank_fund/' + id;
      this.$swal({
        title: 'ทุนสำรองธนาคาร',
        icon: 'warning',
        input: 'number',
        inputLabel: 'เงินทุน',
        inputValue: fund,
        showCancelButton: true,
        confirmButtonText: 'ตกลง',
        cancelButtonText: 'ยกเลิก',
		    cancelButtonColor: 'red',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger mr-1',
        },
        buttonsStyling: false,
		    reverseButtons: true,
      }).then(result => {
        if (result.value) {
          let data = { fund: result.value };
          this.$http.post(url, data).then(res => {
            this.fetch_bank()
            if (res.data == null) return;
            this.$toast({
              component: ToastificationContent,
              position: 'top-right',
              props: {
                title: 'ทำรายการสำเร็จ',
                icon: 'CheckIcon',
                variant: 'success',
                text: res.data.message,
              },
            })
          }).catch(()=>{});
        } else {
        }
      })
    },
  },
  beforeRouteLeave (to, from, next) {
    clearInterval(this.t_fetch_bank)
    clearInterval(this.t_fetch_report_all_day)
    clearInterval(this.t_fetch_history_withdraw)
    clearInterval(this.t_fetch_history_deposit)
    next()
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-sweetalert.scss';

</style>
